var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticStyle:{"border-radius":"8px"},attrs:{"centered":"","closable":false,"maskClosable":false,"footer":null},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('div',{staticClass:"sign-in"},[_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"span":24}},[_c('img',{attrs:{"src":"images/logo.png","alt":""}})]),_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"span":24}},[_c('h3',{staticClass:"font-regular text-muted"},[_vm._v("Dashboard Kit")])]),_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"span":24}},[_c('h1',{staticClass:"mb-15"},[_vm._v("Sign Up to Admin Dashboard")])]),_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"span":24}},[_c('h5',{staticClass:"font-regular text-muted"},[_vm._v(" Enter your email, password and token below ")])])],1),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"EMAIL","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                { required: true, message: 'Please input your email!' } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                { required: true, message: 'Please input your email!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Email address"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"PASSWORD","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Please input your password!' } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Please input your password!' },\n              ],\n            },\n          ]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"TOKEN","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'token',
            {
              rules: [
                { required: true, message: 'Please input your Token!' } ],
            } ]),expression:"[\n            'token',\n            {\n              rules: [\n                { required: true, message: 'Please input your Token!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Token"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"error"},[_vm._v(_vm._s(this.errorMsg))]),_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('h5',{staticClass:"font-semibold text-muted"},[_vm._v(" Have an existing account? "),_c('router-link',{staticClass:"font-bold text-primary",attrs:{"to":"/login"}},[_vm._v("Log in")])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }